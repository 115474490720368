import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import {
  ScaleButton,
  ScaleDropdownSelect,
  ScaleDropdownSelectItem,
} from '@telekom/scale-components-react';

import {
  createProductTypeMember,
  updateProductTypeMember,
  deleteProductTypeMember,
} from '../../../../../redux/safescarf/productTypeMembers/actions';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';

const roleLookup = (roles) => roles?.reduce((acc, role) => {
  acc[role.id] = role.name;
  return acc;
}, {}) || {};


const RoleField = ({ userTypeRoles }) => {
  const { setFieldTouched } = useFormikContext();
  const [field] = useField({ name: 'role' });

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        floatingStrategy="fixed"
        label="Product Type Member Role"
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('role');
        }}
      >
        <ScaleDropdownSelectItem key="none" value="">None</ScaleDropdownSelectItem>
        {Object.keys(userTypeRoles).map((key) => (
          <ScaleDropdownSelectItem key={key} value={key}>
            {userTypeRoles[key]}
          </ScaleDropdownSelectItem>
        ))}
      </ScaleDropdownSelect>
    </div>
  );
};

RoleField.propTypes = {
  userTypeRoles: PropTypes.objectOf(PropTypes.string).isRequired,
};


const EditProductTypeMemberForm = ({ productTypeMember, onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const { productTypeId } = useParams();
  const roles = useSelector((state) => state.safescarf.roles);

  useEffect(() => {
    if (!roles.length) dispatch(fetchRoles());
  }, []);

  const userTypeRoles = productTypeMember.isMachineAccount
    ? roleLookup(roles.filter((role) => role.id === 1))
    : roleLookup(roles.filter((role) => [2, 5].includes(role.id)));

  const updateProductTypeMemeberRole = async (role) => {
    if (productTypeMember.productTypeMemberId) {
      if (role !== '') {
        dispatch(updateProductTypeMember({
          id: productTypeMember.productTypeMemberId,
          product_type: productTypeId,
          user: productTypeMember.userId,
          role,
        }));
      } else {
        dispatch(deleteProductTypeMember(productTypeMember.productTypeMemberId));
      }
    } else if (role !== '') {
      dispatch(createProductTypeMember({
        product_type: productTypeId,
        user: productTypeMember.userId,
        role,
      }));
    }
  };

  const submit = async (formValues) => {
    await updateProductTypeMemeberRole(formValues.role);
    await onSubmit();
  };

  const initialValues = {
    role: productTypeMember.productTypeMemberRole?.toString() || '',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <RoleField userTypeRoles={userTypeRoles} />
          <div className="form-buttons">
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Save
            </ScaleButton>
            <ScaleButton
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={onCancel}
            >
              Cancel
            </ScaleButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditProductTypeMemberForm.propTypes = {
  productTypeMember: PropTypes.shape({
    userId: PropTypes.number,
    productTypeMemberId: PropTypes.number,
    productTypeMemberRole: PropTypes.number,
    isMachineAccount: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

EditProductTypeMemberForm.defaultProps = {
  productTypeMember: undefined,
};


export default EditProductTypeMemberForm;
