const MONITORING_ORGANIZATION = window.PORTAL_MONITORING_ORGANIZATION
|| import.meta.env.VITE_MONITORING_ORGANIZATION;
const AO_AA_BUM_TL_DEV = ['ACCOUNT_OWNER', 'ACCOUNT_ASSISTANT', 'BUSINESS_UNIT_MANAGER', 'TECHNICAL_LEAD', 'DEVELOPER'];
const AA_BUM_TL_DEV = ['ACCOUNT_ASSISTANT', 'BUSINESS_UNIT_MANAGER', 'TECHNICAL_LEAD', 'DEVELOPER'];
const BUM_TL_DEV = ['BUSINESS_UNIT_MANAGER', 'TECHNICAL_LEAD', 'DEVELOPER'];
const TL_DEV = ['TECHNICAL_LEAD', 'DEVELOPER'];
const rules = {
  ADMIN: {
    static: [
      '/organizations:VIEW_ALL',
      '/organizations:VIEW_ALL_DETAILS',
      '/organization:POST',
      '/organization:PUT',
      '/reports',
      '/marketplace:VIEW',
      '/forecast:VIEW',
      '/users:POST',
      '/users:PUT:ORG',
      '/business-units:POST',
      '/business-units:PUT',
      '/business-units:DELETE',
      '/projects:VIEW',
      '/projects:POST',
      '/projects:PATCH',
      '/projects:DELETE',
      '/osprojects:DELETE',
      '/osprojects:PATCH',
      '/products:POST',
      '/products:PUT',
      '/products:PUT:TAGS',
      '/products:CONTACTS_VIEW',
      '/products:CONTACTS_PATCH',
      '/products:CONTACTS_DELETE',
      '/products:DELETE',
    ],
    dynamic: {
      '/users:PUT': (data) => data.userToBeUpdatedEmail !== data.userEmail,
      '/users:DELETE': (data) => data.userToBeDeletedEmail !== data.userEmail,
      '/users:PUT_ROLE_CHECK': () => AO_AA_BUM_TL_DEV,
    },
  },
  SUPPORT: {
    static: [
      '/organizations:VIEW_ALL',
      '/reports',
      '/marketplace:VIEW',
      '/projects:VIEW',
      '/products:CONTACTS_VIEW',
    ],
  },
  DEMAND_MANAGER: {
    static: [
      '/demand-management',
      '/demand:PATCH',
      '/organizations:VIEW_ALL',
    ],
  },
  ACCOUNT_OWNER: {
    static: [
      '/organizations:VIEW_OWN_DETAILS',
      '/users:POST',
      '/business-units:POST',
      '/business-units:PUT',
      '/business-units:DELETE',
      '/projects:VIEW',
      '/projects:PATCH',
      '/products',
      '/settings/cloud-reset-password',
      '/products/safescarf/product-types:POST',
      '/products/safescarf/product-types:PUT_MEMBERS',
      '/products/safescarf/product-types:PUT',
      '/products/safescarf/products:POST',
      '/products/safescarf/products:PUT',
      '/products/safescarf/products/id:PUT',
      '/products/safescarf/products:DELETE',
    ],
    dynamic: {
      '/products/safescarf/users:PUT': (data) => data.userEmail !== data.rowEmail,
      '/users:DELETE': (data) => data.userToBeDeletedEmail !== data.userEmail && AO_AA_BUM_TL_DEV.includes(data.userToBeDeletedRole),
      '/users:PUT': (data) => AA_BUM_TL_DEV.includes(data.userToBeUpdatedRole),
      '/users:PUT_ROLE_CHECK': () => AO_AA_BUM_TL_DEV,
      '/products/compute/order': (organization) => organization.marketplaceItemIds?.find((item) => item.name === 'Compute') !== undefined,
      '/products/safescarf/order': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Safescarf') !== undefined
         && organization.productIds?.find((product) => product.type.toLowerCase() === 'safescarf') === undefined),
      '/products/safescarf': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Safescarf') !== undefined
            && organization.productIds?.find((product) => product.type.toLowerCase() === 'safescarf') !== undefined),
      '/demand-management': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Demands') !== undefined),
      '/osprojects:DELETE': (data) => data.osProjectType !== 'computeTrial' && data.userProducts.includes(data.productId),
      '/monitoring-dashboard': (organizationName) => (organizationName === MONITORING_ORGANIZATION),
    },
  },
  ACCOUNT_ASSISTANT: {
    static: [
      '/organizations:VIEW_OWN_DETAILS',
      '/users:POST',
      '/business-units:POST',
      '/business-units:PUT',
      '/business-units:DELETE',
      '/projects:VIEW',
      '/projects:PATCH',
      '/products',
      '/settings/cloud-reset-password',
      '/products/safescarf/product-types:POST',
      '/products/safescarf/product-types:PUT_MEMBERS',
      '/products/safescarf/product-types:PUT',
      '/products/safescarf/products:POST',
      '/products/safescarf/products:PUT',
      '/products/safescarf/products/id:PUT',
      '/products/safescarf/products:DELETE',
    ],
    dynamic: {
      '/products/safescarf/users:PUT': (data) => data.userEmail !== data.rowEmail,
      '/users:DELETE': (data) => data.userToBeDeletedEmail !== data.userEmail && AA_BUM_TL_DEV.includes(data.userToBeDeletedRole),
      '/users:PUT': (data) => BUM_TL_DEV.includes(data.userToBeUpdatedRole),
      '/users:PUT_ROLE_CHECK': () => AA_BUM_TL_DEV,
      '/products/compute/order': (organization) => organization.marketplaceItemIds?.find((item) => item.name === 'Compute') !== undefined,
      '/products/safescarf/order': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Safescarf') !== undefined
         && organization.productIds?.find((product) => product.type.toLowerCase() === 'safescarf') === undefined),
      '/products/safescarf': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Safescarf') !== undefined
            && organization.productIds?.find((product) => product.type.toLowerCase() === 'safescarf') !== undefined),
      '/demand-management': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Demands') !== undefined),
      '/osprojects:DELETE': (data) => data.osProjectType !== 'computeTrial' && data.userProducts.includes(data.productId),
      '/monitoring-dashboard': (organizationName) => (organizationName === MONITORING_ORGANIZATION),
    },
  },
  BUSINESS_UNIT_MANAGER: {
    static: [
      '/organizations:VIEW_OWN_DETAILS',
      '/users:POST',
      '/business-units:PUT',
      '/projects:VIEW',
      '/projects:PATCH',
      '/products',
      '/settings/cloud-reset-password',
      '/products/safescarf/product-types:POST',
      '/products/safescarf/products:POST',
    ],
    dynamic: {
      '/products/safescarf/users:PUT': (data) => data.userEmail !== data.rowEmail,
      '/users:DELETE': (data) => data.userToBeDeletedEmail !== data.userEmail && BUM_TL_DEV.includes(data.userToBeDeletedRole),
      '/users:PUT_ROLE_CHECK': () => BUM_TL_DEV,
      '/products/compute/order': (organization) => organization.marketplaceItemIds?.find((item) => item.name === 'Compute') !== undefined,
      '/products/safescarf/order': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Safescarf') !== undefined
         && organization.productIds?.find((product) => product.type.toLowerCase() === 'safescarf') === undefined),
      '/products/safescarf': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Safescarf') !== undefined
            && organization.productIds?.find((product) => product.type.toLowerCase() === 'safescarf') !== undefined),
      '/demand-management': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Demands') !== undefined),
      // Verifies that user is a member of this productType, with Writer role
      '/products/safescarf/product-types:PUT': (data) => (
        data.safescarfUser.globalRole === 2
          || data.productType?.members[data.safescarfUser.id]?.role === 2 // Writer
      ),
      '/products/safescarf/product-types:PUT_MEMBERS': (safescarfUser) => (
        safescarfUser?.globalRole === 2 || safescarfUser?.productTypeMemberRole === 2
      ),
      '/products/safescarf/products:PUT': (data) => data.product?.members[data.memberId]?.role === 3, // Maintainer Role
      '/products/safescarf/products/id:PUT': (role) => role === 3, // 3 is Maintainer Role
      '/osprojects:DELETE': (data) => data.osProjectType !== 'computeTrial' && data.userProducts.includes(data.productId),
      '/monitoring-dashboard': (organizationName) => (organizationName === MONITORING_ORGANIZATION),
    },
  },
  TECHNICAL_LEAD: {
    static: [
      '/organizations:VIEW_OWN_DETAILS',
      '/products',
      '/users:POST',
      '/projects:VIEW',
      '/products:PUT',
      '/settings/cloud-reset-password',
      '/products/safescarf/product-types:POST',
      '/products/safescarf/products:POST',
    ],
    dynamic: {
      '/products/safescarf/users:PUT': (data) => data.userEmail !== data.rowEmail,
      '/users:DELETE': (data) => data.userToBeDeletedEmail !== data.userEmail && TL_DEV.includes(data.userToBeDeletedRole),
      '/users:PUT_ROLE_CHECK': () => TL_DEV,
      '/products/compute/order': (organization) => organization.marketplaceItemIds?.find((item) => item.name === 'Compute') !== undefined,
      '/products/caas-on-openstack/order': (organization) => organization.marketplaceItemIds?.find((item) => item.name === 'CaaSonOpenstack') !== undefined,
      '/products/safescarf/order': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Safescarf') !== undefined
         && organization.productIds?.find((product) => product.type.toLowerCase() === 'safescarf') === undefined),
      '/products/safescarf': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Safescarf') !== undefined
            && organization.productIds?.find((product) => product.type.toLowerCase() === 'safescarf') !== undefined),
      '/demand-management': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Demands') !== undefined),
      '/products/safescarf/product-types:PUT': (data) => (
        data.safescarfUser.globalRole === 2
          || data.productType?.members[data.safescarfUser.id]?.role === 2 // Writer
      ),
      '/products/safescarf/product-types:PUT_MEMBERS': (safescarfUser) => (
        safescarfUser?.globalRole === 2 || safescarfUser?.productTypeMemberRole === 2
      ),
      '/products/safescarf/products:PUT': (data) => data.product?.members[data.memberId]?.role === 3, // Maintainer Role
      '/products/safescarf/products/id:PUT': (role) => role === 3, // 3 is Maintainer Role
      '/osprojects:DELETE': (data) => data.osProjectType !== 'computeTrial' && data.userProducts.includes(data.productId),
      '/monitoring-dashboard': (organizationName) => (organizationName === MONITORING_ORGANIZATION),
    },
  },
  DEVELOPER: {
    static: [
      '/organizations:VIEW_OWN_DETAILS',
      '/projects:VIEW',
      '/products',
      '/settings/cloud-reset-password',
      '/products/safescarf/product-types:POST',
      '/products/safescarf/products:POST',
    ],
    dynamic: {
      '/products/safescarf/product-types:PUT': (data) => (
        data.safescarfUser.globalRole === 2
          || data.productType?.members[data.safescarfUser.id]?.role === 2 // Writer
      ),
      '/products/safescarf/product-types:PUT_MEMBERS': (safescarfUser) => (
        safescarfUser?.globalRole === 2 || safescarfUser?.productTypeMemberRole === 2
      ),
      '/products/safescarf/products:PUT': (data) => data.product?.members[data.memberId]?.role === 3, // Maintainer Role
      '/products/safescarf/products/id:PUT': (role) => role === 3, // 3 is Maintainer Role
      '/products/compute/order': (organization) => organization.marketplaceItemIds?.find((item) => item.name === 'Compute') !== undefined,
      '/products/safescarf/order': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Safescarf') !== undefined
         && organization.productIds?.find((product) => product.type.toLowerCase() === 'safescarf') === undefined),
      '/products/safescarf': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Safescarf') !== undefined
            && organization.productIds?.find((product) => product.type.toLowerCase() === 'safescarf') !== undefined),
      '/demand-management': (organization) => (
        organization.marketplaceItemIds?.find((item) => item.name === 'Demands') !== undefined),
      '/osprojects:DELETE': (data) => data.osProjectType !== 'computeTrial' && data.userProducts.includes(data.productId),
      '/monitoring-dashboard': (organizationName) => (organizationName === MONITORING_ORGANIZATION),
    },
  },
};

export default rules;
