import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  useField,
} from 'formik';
import {
  ScaleButton,
  ScaleTextField,
  ScaleDropdownSelect,
  ScaleDropdownSelectItem,
} from '@telekom/scale-components-react';


const initialValues = {
  email: '',
  role: '',
};

const EmailField = () => {
  const [field, meta] = useField({ name: 'email' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Email"
        helperText={meta.touched ? meta.error : null}
        onScale-change={field.onChange}
      />
    </div>
  );
};

const RoleField = ({ roles }) => {
  const [field] = useField({ name: 'role' });

  return (
    <div className="form-field">
      <ScaleDropdownSelect
        {...field}
        style={{ height: '10vh' }}
        label="Role"
        floatingStrategy="fixed"
        onScale-change={field.onChange}
      >
        {Object.keys(roles).map(
          (key) => (
            <ScaleDropdownSelectItem key={key} value={key}>
              {roles[key]}
            </ScaleDropdownSelectItem>
          ),
        )}
      </ScaleDropdownSelect>
    </div>
  );
};

RoleField.propTypes = { roles: PropTypes.objectOf(PropTypes.string).isRequired };


const CreateUserForm = ({ roles, onSubmit, onCancel }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required').email('Invalid email address'),
    role: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <EmailField />
          <RoleField roles={roles} />
          <div className="form-buttons">
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Create
            </ScaleButton>
            <ScaleButton
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={onCancel}
            >
              Cancel
            </ScaleButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CreateUserForm.propTypes = {
  roles: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export default CreateUserForm;
