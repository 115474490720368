import { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Table from '../../../../../components/Table';

import { fetchMachineAccounts } from '../../../../../redux/safescarf/machineAccounts/actions';
import { fetchProductTypeMembers } from '../../../../../redux/safescarf/productTypeMembers/actions';
import { fetchRoles } from '../../../../../redux/safescarf/roles/actions';


function getMember(user, productTypeMembers, isMachineAccount) {
  const productTypeMember = productTypeMembers.find((ptm) => ptm.user === user.id);

  return {
    userId: user.id,
    username: user.username,
    createdBy: user.email,
    globalRole: user.globalRole,
    productTypeMemberId: productTypeMember?.id,
    productTypeMemberRole: productTypeMember?.role,
    isMachineAccount,
  };
}

function getMembers(safescarfUsers, machineAccounts, productTypeMembers) {
  const activeSafescarfUsers = safescarfUsers.filter((user) => user.is_active);
  const activeMachineAccounts = machineAccounts.filter((ma) => ma.is_active);
  const decoratedSafescarfUsers = activeSafescarfUsers.map(
    (user) => getMember(user, productTypeMembers, false),
  );
  const decoratedMachineAccounts = activeMachineAccounts.map(
    (ma) => getMember(ma, productTypeMembers, true),
  );
  return [...decoratedSafescarfUsers, ...decoratedMachineAccounts];
}

const ProductTypeMembers = () => {
  const { productTypeId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [members, setMembers] = useState([]);

  const roles = useSelector((state) => state.safescarf.roles);
  const safescarfUsers = useSelector((state) => state.safescarf.users);
  const productTypeMembers = useSelector((state) => state.safescarf.productTypeMembers
    .filter((ptm) => ptm.product_type === Number(productTypeId)), shallowEqual);
  const machineAccounts = useSelector((state) => state.safescarf.machineAccounts);

  useEffect(() => {
    if (!roles.length) dispatch(fetchRoles());
    if (!machineAccounts.length) dispatch(fetchMachineAccounts());
    if (!productTypeMembers.length) dispatch(fetchProductTypeMembers());
  }, []);

  useEffect(() => {
    // TODO: Move outside the useEffect
    setMembers(getMembers(safescarfUsers, machineAccounts, productTypeMembers));
  }, [safescarfUsers, productTypeMembers, machineAccounts]);

  const getPrioritizedRole = (member) => {
    const getRoleName = (roleId) => roles.find((r) => r.id === roleId)?.name;
    const { globalRole, productTypeMemberRole } = member;

    // Pick the higher role based on hierarchy (2 > 5)
    if (globalRole && productTypeMemberRole) {
      if (globalRole <= productTypeMemberRole) {
        return `${getRoleName(globalRole)} (Global)`;
      }
      return getRoleName(productTypeMemberRole);
    }

    if (globalRole) return `${getRoleName(globalRole)} (Global)`;
    if (productTypeMemberRole) return getRoleName(productTypeMemberRole);

    return 'Not assigned';
  };


  const mapMembersToRows = members.map((member) => ({
    key: member.userId.toString(),
    onRowClick: () => navigate(`members/${member.userId.toString()}`),
    data: [
      {
        field: 'safescarfUserName',
        value: member.username,
      },
      {
        field: 'isMachineAccount',
        value: member.isMachineAccount ? 'Machine account' : 'User',
      },
      {
        field: 'createdBy',
        value: member.createdBy,
      },
      {
        field: 'productTypeMemberRole',
        value: getPrioritizedRole(member),
      },
    ],
  }));


  return (
    <Table
      header="Members"
      fields={[
        {
          id: 'safescarfUserName',
          title: 'Username',
        },
        {
          id: 'isMachineAccount',
          title: 'Account type',
        },
        {
          id: 'createdBy',
          title: 'Created by',
        },
        {
          id: 'productTypeMemberRole',
          title: 'Permission',
        },
      ]}
      rows={mapMembersToRows || []}
    />
  );
};


export default ProductTypeMembers;
