import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { ScaleButton } from '@telekom/scale-components-react';
import { Grid, Typography } from '@mui/material';
import * as Yup from 'yup';
import { FormikTextField } from '../Formik/index';

const DeleteForm = ({
  fieldName,
  fieldValue,
  messages,
  deleteFunc,
  onClose,
}) => {
  const intl = useIntl();
  const ValidationSchema = Yup.object().shape({
    deleteInput: Yup.string().oneOf(
      [fieldValue, null],
      `Entered value should match current ${fieldName}`,
    ),
  });

  return (
    <>
      {messages.map((message) => (
        <Typography key={message} gutterBottom>
          {intl.formatMessage({ id: message })}
        </Typography>
      ))}
      <Formik
        initialErrors={{ deleteInput: '' }}
        initialValues={{ deleteInput: '' }}
        validationSchema={ValidationSchema}
        onSubmit={deleteFunc}
      >
        {({ dirty, isValid, resetForm }) => (
          <Form>
            <Grid
              container
              gap={2}
              py={2}
            >
              <Grid item xs={12}>
                <FormikTextField
                  name="deleteInput"
                  label={fieldName}
                />
              </Grid>
              <Grid
                container
                justifyContent="end"
                gap={1}
              >
                <Grid item>
                  <ScaleButton
                    type="submit"
                    variant="primary"
                    disabled={!dirty || !isValid}
                  >
                    Delete
                  </ScaleButton>
                </Grid>
                <Grid item>
                  <ScaleButton
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      resetForm();
                      onClose();
                    }}
                  >
                    Cancel
                  </ScaleButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

DeleteForm.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string),
  deleteFunc: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DeleteForm.defaultProps = {
  messages: [],
};


export default DeleteForm;
