import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Form,
} from 'formik';
import {
  ScaleButton,
} from '@telekom/scale-components-react';
import validator from 'validator';
import { Grid } from '@mui/material';
import Provision from '../../api/provision';
import useApiCall from '../../hooks/useApiCall';
import { FormikSelect, FormikTextField } from '../../components/Formik/index';

const CreateContactForm = ({
  formRef, juiceGroup, onSubmit, onCancel,
}) => {
  const addContact = useApiCall(Provision.addContact, 'ContactAdded');

  const initialValues = {
    email: '',
    type: '',
    name: '',
    phoneNumber: '',
    slackChannel: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Required')
      .email('Invalid email address'),
    type: Yup.string()
      .required('Required'),
    phoneNumber: Yup.string()
      .test(
        'is-valid-phone',
        'Invalid phone number',
        (value) => !value || validator.isMobilePhone(value, 'any', { strictMode: true }),
      ),
  });

  const submit = async (formValues) => {
    const [data] = await addContact({ ...formValues, juiceGroup });
    onSubmit(data.contacts);
    formRef.current.resetForm();
  };

  const roles = {
    stakeHolder: 'Stakeholder',
    serviceOwner: 'Service Owner',
    tenantOwner: 'Tenant Owner',
  };

  const roleFieldItems = Object.keys(roles).map((key) => ({
    value: key,
    label: roles[key],
  }));

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid
            container
            gap={2}
            py={2}
          >
            <Grid item xs={12}>
              <FormikTextField
                name="email"
                label="Email"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikSelect
                name="type"
                label="Role"
                menuItems={roleFieldItems}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                name="name"
                label="Name"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                name="phoneNumber"
                label="Phone Number"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                name="slackChannel"
                label="Slack Channel"
              />
            </Grid>
            <Grid
              container
              justifyContent="end"
              gap={1}
            >
              <Grid item>
                <ScaleButton
                  variant="primary"
                  disabled={!dirty || !isValid}
                >
                  Create
                </ScaleButton>
              </Grid>
              <Grid item>
                <ScaleButton
                  name="cancel"
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    formRef.current.resetForm();
                    onCancel();
                  }}
                >
                  Cancel
                </ScaleButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CreateContactForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({
      resetForm: PropTypes.func.isRequired,
    }),
  }).isRequired,
  juiceGroup: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateContactForm;
