import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import { ScaleButton, ScaleTextField } from '@telekom/scale-components-react';

import * as Yup from 'yup';
import validator from 'validator';

import Provision from '../../api/provision';
import useApiCall from '../../hooks/useApiCall';


const NameField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'name' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Name"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('name');
        }}
      />
    </div>
  );
};

const PhoneNumberField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'phoneNumber' });

  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Phone Number"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('phoneNumber');
        }}
      />
    </div>
  );
};

const SlackChannelField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'slackChannel' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Slack Channel"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('slackChannel');
        }}
      />
    </div>
  );
};


const EditContactForm = ({
  formRef, juiceGroup, contact, onSubmit, onCancel,
}) => {
  const editContact = useApiCall(Provision.editContact, 'ContactEdited', null);

  const submit = async (formValues) => {
    const [data] = await editContact({ ...formValues, juiceGroup, id: contact._id });
    onSubmit(data.contacts);
    formRef.current.resetForm();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().ensure(),
    phoneNumber: Yup.string().test({
      message: 'Please input valid phone number prefixed with country code',
      test: (value) => (value !== undefined ? validator.isMobilePhone(value, 'any', { strictMode: true }) : true),
    }),
    slackChannel: Yup.string(),
  });

  const initialValues = {
    name: contact.name,
    phoneNumber: contact.phoneNumber,
    slackChannel: contact.slackChannel,
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ dirty, isValid }) => (
        <Form>
          <NameField />
          <PhoneNumberField />
          <SlackChannelField />
          <div className="form-buttons">
            <ScaleButton
              variant="primary"
              disabled={!dirty || !isValid}
            >
              Save
            </ScaleButton>
            <ScaleButton
              name="cancel"
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={() => {
                formRef.current.resetForm();
                onCancel();
              }}
            >
              Cancel
            </ScaleButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditContactForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({ resetForm: PropTypes.func.isRequired }),
  }).isRequired,
  contact: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  juiceGroup: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export default EditContactForm;
