import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import {
  ScaleButton,
  ScaleTextField,
  ScaleCheckbox,
  ScaleTag,
} from '@telekom/scale-components-react';
import Typography from '@mui/material/Typography';
import Provision from '../../../../../api/provision';
import useApiCall from '../../../../../hooks/useApiCall';
import rbacCheck from '../../../../../rbac/rbacCheck';
import Auth from '../../../../../api/auth';

const DescriptionField = () => {
  const { setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name: 'description' });
  return (
    <div className="form-field">
      <ScaleTextField
        {...field}
        label="Description"
        helperText={meta.touched ? meta.error : null}
        onScale-change={(e) => {
          field.onChange(e);
          setFieldTouched('description');
        }}
      />
    </div>
  );
};

const Tags = ({ tags, setTags }) => {
  const fetchTags = useApiCall(Provision.fetchTags, null, null);
  const [juiceTags, setJuiceTags] = useState([]);

  useEffect(() => {
    async function getTags() {
      const [data] = await fetchTags();
      if (data) setJuiceTags(data);
    }
    getTags();
  }, []);

  return (
    <>
      <Typography align="left">
        Juice Group Tags:
      </Typography>
      <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
        {juiceTags.map((tag) => (
          <ScaleCheckbox
            key={tag}
            style={{ marginTop: '0.5rem' }}
            checked={tags.includes(tag)}
            onScale-change={(event) => {
              if (event.target.checked) {
                setTags([...tags, tag]);
              } else {
                setTags(tags.filter((t) => t !== tag));
              }
            }}
          >
            <ScaleTag type="strong">{tag}</ScaleTag>
          </ScaleCheckbox>
        ))}
      </div>
    </>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTags: PropTypes.func.isRequired,
};

const EditProductForm = ({
  formRef, product, onSubmit, onCancel,
}) => {
  const updateProductCall = useApiCall(Auth.updateV2Product);

  const [tags, setTags] = useState(product?.details?.tags || []);
  const userData = useSelector((state) => state.user);
  const canEditTags = rbacCheck(userData.roles, '/products:PUT:TAGS');

  const updateProduct = async (payload) => {
    const [data] = await updateProductCall({
      ...payload,
      tags: canEditTags ? tags : undefined,
      id: product?._id,
    });
    if (data) onSubmit();
  };

  const initialValues = {
    description: product?.description || '',
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required('Required'),
  });

  const submit = (formValues) => {
    updateProduct(formValues);
    formRef.current.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ isValid, dirty }) => (
        <Form>
          <DescriptionField />
          {canEditTags && <Tags tags={tags} setTags={setTags} />}
          <div className="form-buttons">
            <ScaleButton
              variant="primary"
              disabled={!isValid || (!dirty && !canEditTags)}
            >
              Save
            </ScaleButton>
            <ScaleButton
              name="cancel"
              type="button"
              variant="secondary"
              style={{ marginLeft: '1em' }}
              onClick={() => {
                setTags([]);
                formRef.current.resetForm();
                onCancel();
              }}
            >
              Cancel
            </ScaleButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditProductForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({ resetForm: PropTypes.func.isRequired }),
  }).isRequired,
  product: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export default EditProductForm;
