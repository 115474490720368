import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Grid, Typography } from '@mui/material';
import { ScaleButton } from '@telekom/scale-components-react';
import * as Yup from 'yup';
import { FormikDateTimeField } from '../../../../components/Formik/index';

const nowDate = new Date().toISOString().slice(0, 16);
const nowMinus30Days = new Date(
  new Date().setDate(new Date().getDate() - 30),
).toISOString().slice(0, 16);

const DateTimeFilter = ({ setDateRange }) => (
  <Formik
    initialValues={{
      fromDate: nowMinus30Days,
      toDate: nowDate,
    }}
    validationSchema={Yup.object().shape({
      fromDate: Yup.date().required('Required')
        .max(Yup.ref('toDate'), 'From date must be before To date'),
      toDate: Yup.date().max(nowDate).required('Required')
        .min(Yup.ref('fromDate'), 'To date must be after From date'),
    })}
    onSubmit={(data) => setDateRange({ from: data.fromDate, to: data.toDate })}
  >
    {() => (
      <Form style={{ margin: '1rem' }}>
        <Grid container spacing={1}>
          <Grid item xs={0.5} display="flex" alignItems="center">
            <Typography>Time Range: </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormikDateTimeField
              name="fromDate"
              label="From"
              inputProps={{ max: nowDate }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormikDateTimeField
              name="toDate"
              label="To"
              inputProps={{ max: nowDate }}
            />
          </Grid>
          <Grid item xs={1}>
            <ScaleButton type="submit">
              Submit
            </ScaleButton>
          </Grid>
        </Grid>
      </Form>
    )}
  </Formik>
);

DateTimeFilter.propTypes = {
  setDateRange: PropTypes.func.isRequired,
};

export default DateTimeFilter;

