import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import {
  ScaleCard, ScaleIconAlertInformation,
  ScaleTooltip,
} from '@telekom/scale-components-react';
import { Breadcrumbs, Typography } from '@mui/material';
import { useParams, Link } from 'react-router-dom';

import DateTimeFilter from './DateTimeFilter';
import Stat from './Stat';
import BarChartComponent from './BarChart';
import TimeSeries from './TimeSeries';
import GaugeComponent from './Gauge';
import MonitoringTable from './Table';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

const MonitoringComponents = ({ panelInfo, dateRange }) => {
  if (panelInfo.type === 'text') {
    return (
      <Markdown>{panelInfo.content}</Markdown>
    );
  }
  if (panelInfo.type === 'stat') {
    return (
      <Stat
        panelInfo={panelInfo}
        dateRange={dateRange}
        styles={{ height: '50rem', fontSize: '20rem' }}
      />
    );
  }
  if (panelInfo.type === 'barchart') {
    return (
      <BarChartComponent
        panelInfo={panelInfo}
        dateRange={dateRange}
        styles={{ height: 1000 }}
      />
    );
  }
  if (panelInfo.type === 'timeseries') {
    return (
      <TimeSeries panelInfo={panelInfo} dateRange={dateRange} styles={{ height: 1000 }} />
    );
  }
  if (panelInfo.type === 'gauge') {
    return (
      <GaugeComponent panelInfo={panelInfo} dateRange={dateRange} styles={{ height: 1000 }} />
    );
  }
  if (panelInfo.type === 'table') {
    return (
      <MonitoringTable panelInfo={panelInfo} dateRange={dateRange} styles={{ height: '65rem' }} />
    );
  }
  return null;
};

MonitoringComponents.propTypes = {
  panelInfo: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};

const nowDate = new Date().toISOString().slice(0, 16);
const nowMinusSixMonths = new Date(
  new Date().setMonth(new Date().getMonth() - 6),
).toISOString().slice(0, 16);

const PanelDetails = () => {
  const { panelId } = useParams();
  const [panelData, setPanelData] = useState();
  const [dashboardName, setDashboardName] = useState();
  const [dateRange, setDateRange] = useState({
    from: nowMinusSixMonths,
    to: nowDate,
  });
  const getDashboardApiCall = useApiCall(MonitoringMs.getDashboard);

  const getDashboard = async () => {
    const [data] = await getDashboardApiCall();
    if (data) {
      setDashboardName(data.title);
      const panel = data.rows.find(
        (row) => row.panels.find((p) => p.id.toString() === panelId),
      ).panels.find((p) => p.id.toString() === panelId);

      setPanelData(panel);
    }
  };

  useEffect(() => {
    getDashboard();
  }, []);
  return panelData ? (
    <div style={{ margin: '1rem -10%' }}>
      <Breadcrumbs separator=" > ">
        <Link to="/monitoring-dashboard">{dashboardName}</Link>
        <Typography>{panelData.title}</Typography>
      </Breadcrumbs>
      <DateTimeFilter setDateRange={setDateRange} />
      <ScaleCard>
        <div style={{ height: '50rem' }}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h5" noWrap>{panelData.title}</Typography>
            <ScaleTooltip content={panelData.description}>
              <ScaleIconAlertInformation size={20} style={{ marginTop: '0.2rem', marginLeft: '0.5rem' }} />
            </ScaleTooltip>
          </div>
          <MonitoringComponents panelInfo={panelData} dateRange={dateRange} />
        </div>
      </ScaleCard>
    </div>
  ) : null;
};

export default PanelDetails;
