/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import Table from '../../../components/Table';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';


const decorateMarketplaceItems = (marketplaceItems, activatedItemIds = []) => (
  marketplaceItems.map((marketplaceItem) => ({
    id: marketplaceItem._id,
    name: marketplaceItem.name,
    activated: activatedItemIds.includes(marketplaceItem._id),
  }))
);

const MarketplaceItems = ({ organization, setOrganization }) => {
  const [marketplaceItems, setMarketplaceItems] = useState([]);

  const getMarketplaceItemsCall = useApiCall(Auth.fetchV1MarketplaceItems);
  const activateMarketplaceItemCall = useApiCall(Auth.activateMarketplaceItem);

  const getAllMarketplaceItems = async () => {
    const [data] = await getMarketplaceItemsCall();
    if (data) setMarketplaceItems(data);
  };

  const activateItem = async (item) => {
    const [data] = await activateMarketplaceItemCall({
      _id: organization._id,
      marketplaceItemId: item.id,
    });
    if (data) setOrganization(data);
  };

  useEffect(() => {
    getAllMarketplaceItems();
  }, []);

  const isOrgOlderThanOneYear = () => {
    const yearInMilliseconds = 365 * 24 * 3600 * 1000;
    const orgOlderThanOneYear = new Date()
      - new Date(organization.createdAt) > yearInMilliseconds;
    return orgOlderThanOneYear;
  };

  const tableRows = decorateMarketplaceItems(marketplaceItems, organization.marketplaceItemIds).map(
    (item) => ({
      key: item.id,
      data: [
        {
          field: 'name',
          value: item.name,
        },
        {
          field: 'status',
          type: 'switch',
          props: {
            disabled: item.activated || (item.name === 'Evaluation' && isOrgOlderThanOneYear()),
            checked: item.activated,
            label: item.activated ? 'Active' : 'Inactive',
            'onScale-change': () => {
              if (!item.activated) activateItem(item);
            },
          },
        },
      ],
    }),
  );

  return (
    <Table
      header="Marketplace"
      fields={[
        { id: 'name', title: 'Name' },
        { id: 'status', title: 'Status' },
      ]}
      rows={tableRows}
    />
  );
};


export default MarketplaceItems;
